const target = document.getElementById('newsprk_menu');
function generateCategoryHtml(categories) {
  let html = '';
  categories.forEach(category => {
    const hasChildren = category.hasOwnProperty('children') && category['children'].length > 0;
    const hasParent = category['parent_id'] !== 0;
    const link = '/'+category.name.replaceAll(' ','-').toLowerCase();
    const iconClass = hasChildren && !hasParent ? 'fa-angle-down' : 'fa-angle-right';
    const hasIcon = hasChildren ? `&nbsp;&nbsp;&nbsp;<i class="fa ${iconClass}"></i>` : '';

    html += `<li><a href="${link}">${category.name}${hasIcon}</a>`;

    if (hasChildren) {
      html += '<ul>';
      html += generateCategoryHtml(category.children);
      html += '</ul>';
    }

    html += '</li>';
  });

  return html;
}

let menu = generateCategoryHtml(categories);
menu += '<li id="more" class="has-sub"><a>More&nbsp;&nbsp;&nbsp;<i class="fa fa-angle-down"></i></a></li>';
target.insertAdjacentHTML("beforeend", menu);
const more = `<ul>${generateCategoryHtml(category)}</ul>`;
const target1 = document.getElementById('more');
target1.insertAdjacentHTML("beforeend", more);

//end navigator

// const user = document.getElementById('login_user')
// const login_box = document.querySelector('.user__login_box')
// const login_close = document.querySelector('.login_close')
// user.onclick = ()=>{
//     login_box.setAttribute('style','display:flex;')
// }
// login_close.onclick = ()=>{
//     login_box.removeAttribute('style')
// }

//search form
document.addEventListener('DOMContentLoaded', function() {
    var searchForm = document.getElementById('searchForm');

    searchForm.addEventListener('submit', function(event) {
        event.preventDefault(); // Prevent default form submission

        var term = document.querySelector('input[name="term"]').value; // Get the search term

        var url = '/search/' + encodeURIComponent(term); // Customize the URL

        window.location.href = url; // Redirect to the custom URL
    });
});
 //share function
 const url = window.location.href; // Replace with your website URL
function shareOnFacebook() {
    var facebookShareUrl = 'https://www.facebook.com/sharer.php?u=' + encodeURIComponent(url);
    window.open(facebookShareUrl, '_blank');
}

function shareOnTwitter() {
    var twitterShareUrl = 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(url);
    window.open(twitterShareUrl, '_blank');
}

function shareOnLinkedIn() {
    var linkedInShareUrl = 'https://www.linkedin.com/shareArticle?url=' + encodeURIComponent(url);
    window.open(linkedInShareUrl, '_blank');
}

function shareOnWhatsApp() {
    var whatsAppShareUrl = 'whatsapp://send?text=' + encodeURIComponent(url);
    window.open(whatsAppShareUrl, '_blank');
}

function shareOnInstagram() {
    var instagramShareUrl = 'instagram://share?text=' + encodeURIComponent(url);
    window.open(instagramShareUrl, '_blank');
}

function sharefunction(appLink){
    if (navigator.share) {
        // Use the native share API if supported by the browser
        navigator.share({
            title: 'Check out our mobile app!',
            text: 'Download and explore our awesome mobile app!',
            url: appLink
        });
    }
}

function sharemore(event) {
    event.preventDefault();
    const appLink = url; // Replace with the link to your mobile app
    sharefunction(appLink);
}

function shareMobileAppLink(url) {
    event.preventDefault();
    const appLink = url.href; // Replace with the link to your mobile app
    sharefunction(appLink);
}

const scrollToTopButton = document.querySelector(".up_btn");
scrollToTopButton.addEventListener("click", (e) => {
    e.preventDefault();
  window.scrollTo({ top: 0, behavior: "smooth" });
});